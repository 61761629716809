<template>
  <div>
    <CRow>
      <CCol sm="12">
        <h3>Editando</h3>
        <p><strong>Reparación:</strong> {{this.reparacion.name}} - {{this.reparacion.modelo_name}} - {{this.marca.name}}</p>
        <br>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="4">
        <CCard>
          <CForm v-bind:wasValidated="this.validationError">
            <CCardBody>
                <CToaster v-if="errores.length" :autohide="3000">
                  <template v-for="error in errores">
                    <CToast
                      color="danger"
                      :key="'error' + error"
                      :show="true"
                      header="Error de validación"
                    >
                      {{error}}
                    </CToast>
                  </template>
                </CToaster>
              <CSelect ref="tipo_reparacion"
                label="Tipo de reparación"
                :value.sync="reparacion.name"
                :options="generateProblemasSelect()"
                placeholder="Selecciona una opción"
                autocomplete="modelo"
                description="Si la opción que estabas buscando no esta diponible, solicita a los administradores que agreguen esta opción."
                required
              />
              <CInput v-model="reparacion.description"
                type="text"
                description="Tip: ¿Qué incluye la reparación?"
                autocomplete="reparacionDesc"
                label="Descripción"
                placeholder=""
                required
              />
              <CInput v-model="reparacion.price"
                type="number"
                description="Solo números"
                autocomplete="reparacionPrice"
                label="Precio"
                placeholder=""
                required
              />
              <CSelect ref="modelo"
                  label="Modelo"
                  :update="updateModeloName()"
                  :value.sync="reparacion.modelo_id"
                  :options="generateModelosSelect()"
                  placeholder="Selecciona un modelo"
                  autocomplete="modelo"
                  description="Si tu modelo no se muestra acá, deberías agregarlo al listado de Modelos"
                  required
                />
                <CInputCheckbox
                      :key="this.reparacion.stock"
                      :label="'En Stock'"
                      :value="this.reparacion.stock"
                      :custom="true"
                      :checked.sync="reparacion.stock"
                    />
            </CCardBody>
            <CCardFooter>
              <CButtonGroup>
                <CButton v-on:click.prevent="triggerUpdateReparacion()" type="submit" color="success">Guardar cambios</CButton>
                <CButton v-on:click="triggerBack()" color="info">Volver</CButton>
              </CButtonGroup>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
      <CCol v-if="reparaciones.length > 0" sm="8">
        <CCard>
          <CCardBody>
            <h3>Otras reparaciones para el {{this.reparacion.modelo_name}}</h3>
            <CDataTable :alignVertical="true"
              :items="reparaciones"
              :fields="fields"
              :items-per-page="200"
              :columnFilter="false"
              :tableFilter="false"
              :sorterValue="{ asc: true }"
              :sorter='{ external: false, resetable: true }'
              pagination
            >
            <template #price="{item}">
              <td>
                <span>${{item.price}}</span>
              </td>
            </template>
            <template #stock="{item}">
              <td>
                <CBadge :color="getBadge(item.stock)">{{parseStock(item.stock)}}</CBadge>
              </td>
            </template>
            <template #createdAt="{item}">
              <td>
                <span>{{new Date(item.createdAt).getDate() + '/' +new Date(item.createdAt).getMonth()+ '/'+new Date(item.createdAt).getFullYear()+ ' ' +  new Date(item.createdAt).getHours() + ':' + new Date(item.createdAt).getMinutes()}}</span>
              </td>
            </template>
            <template #acciones="{item}">
              <td>
                <CButtonGroup >
                  <CButton v-on:click="triggerRouterUpdateReparacion(item)" v-c-tooltip="'Editar'" color="info"><CIcon name="cil-pencil" /></CButton>
                  <CButton v-on:click="triggerDeleteReparacion(item)" v-c-tooltip="'Eliminar'" color="danger"><CIcon name="cil-x-circle" /></CButton>
                </CButtonGroup>
              </td>
            </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const Swal = require('sweetalert2');
const problemasService = require('../../services/problemas.service');
const reparacionesService = require('../../services/reparaciones.service');
const modelosService = require('../../services/modelos.service');

export default {
  name: 'editarReparacion',
  data() {
    return {
      fields: this.generateFields(),
      reparacion: {},
      modelo: {},
      marca: {},
      reparaciones: [{}],
      problemas: [{}],
      modelos: [{}],
      errores: [],
      validationError: false,
      isLoading: true,
    }
  },
  created() {
    this.getProblemas(this.$store.state.user.data.id);
    this.getReparacion(this.$route.params.id);
    this.getReparaciones();
    this.getModelos();
  },
  updated() {

  },
  methods: {
    getProblemas: function() {
      problemasService.getProblemas(this.$store.state.user.data.id).then((response) => {
        // handle success
        let { problemas } = response.data
        this.problemas = problemas;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    getReparacion: function(id) {
      reparacionesService.getReparacion(id).then((response) => {
        // handle success
        let { reparacion } = response.data;
        let { Modelo } = response.data.reparacion;
        let { Marca } = Modelo;
        this.reparacion = reparacion;
        this.modelo = Modelo;
        this.marca = Marca;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    getReparaciones: function() {
      reparacionesService.getReparaciones(this.$store.state.user.data.id).then((response) => {
        // handle success
        let { reparaciones } = response.data
        this.reparaciones = reparaciones.filter(reparacion => {
          return reparacion.modelo_name == this.reparacion.modelo_name && reparacion.name != this.reparacion.name;
        });
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    getModelos: function() {
      modelosService.getModelos(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.modelos = response.data.modelos;
        this.isLoading = false;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    generateModelosSelect(){
      let select = [];
      this.modelos.forEach(modelo => {
        if(modelo.marca_id == this.modelo.marca_id)
          select.push({value: modelo.id, label: modelo.name})
        });
      return select;
    },
    generateProblemasSelect(){
      let select = [];
      this.problemas.forEach(problema => {
        if(problema.marca_id == this.marca.id)
          select.push({value: problema.reparacion_name, label: problema.reparacion_name})
        });

      console.log(select);
      return select;
    },
    generateFields(){
      const fields = [{
        key: 'name' ,
        label: 'Reparación',
      },{
        key: 'price' ,
        label: 'Precio',
      },{
        key: 'stock' ,
        label: 'Stock',
      },{
        key: 'createdAt' ,
        label: 'Fecha',
      },{
        key: 'acciones' ,
        label: 'Acciones',
        _style: 'width: 100px;',
        filter: false,
        sorter: false
      }];

      return fields;
    },
    getBadge (status) {
      return status === true ? 'success'
          : status === false ? 'danger' : 'secondary'
    },
    parseStock (stock) {
      return stock === true ? 'En stock'
        : stock === false ? 'Sin stock' : ''
    },
    updateModeloName () {
      this.modelos.forEach(modelo => {
        if(modelo.id == this.reparacion.modelo_id){
          this.reparacion.modelo_name = modelo.name;
        }
      });
    },
    checkForm: function () {
      if (this.reparacion.name && this.reparacion.price) {
        return true;
      }

      this.errores = [];
      this.validationError = true;
      if (!this.reparacion.name) {
        this.errores.push('Nombre es obligatorio.');
      }
      if (!this.reparacion.price) {
        this.errores.push('Falta agregar un precio.');
      }
    },
    triggerBack(){
      this.$router.push('/reparaciones');
    },
    async triggerUpdateReparacion(){
      await Swal.fire({
        title: 'Guardar cambios',
        html: `Vas a actualizar los datos para la reparación: <br>${this.reparacion.name} - ${this.reparacion.modelo_name} <br><br><strong>¿Estás seguro?</strong>`,
        showDenyButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          reparacionesService.updateReparacion(this.reparacion.id, this.reparacion).then(() => {
            Swal.fire('¡Operación realizada!', 'La reparacion fue actualizada', 'success');
          }).catch((error) => {
            // handle error
            console.log(error);
            Swal.fire('Hubo un error', 'Consulte al administrador', 'error')
          });
        } else if (result.isDenied) {
          Swal.fire('Operación cancelada', '', 'error');
        }
      });
    },
    async triggerRouterUpdateReparacion(reparacion){
      this.$router.push('/reparaciones/editarReparacion/' + reparacion.id);
    },
    async triggerDeleteReparacion(reparacion){
      await Swal.fire({
        title: 'Eliminar reparacion',
        html: `Vas a eliminar la reparacion: <br>${reparacion.name} - ${reparacion.modelo_name} <br><br><strong>¿Estás seguro?</strong>`,
        showDenyButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          reparacionesService.deleteReparacion(reparacion.id).then(() => {
            Swal.fire('¡Operación realizada!', 'La reparacion fue eliminada', 'success');
            this.reparaciones = this.reparaciones.filter(function(item) {
                return item.id != reparacion.id;
            });
          }).catch((error) => {
            // handle error
            console.log(error);
            Swal.fire('Hubo un error', 'Consulte al administrador', 'error')
          });
        } else if (result.isDenied) {
          Swal.fire('Operación cancelada', '', 'error');
        }
      });
    }
  }
}
</script>
